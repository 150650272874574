@import "./btnGeneral.module";

.root{
  width:100%;
  min-height:100vh;
  color:white;
  text-align:center;
  padding:10vh 0;
  transition:.3s all ease-out;
  background-color: #424187;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 60 60'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.15' fill-rule='nonzero'%3E%3Cpath d='M29 58.58l7.38-7.39A30.95 30.95 0 0 1 29 37.84a30.95 30.95 0 0 1-7.38 13.36l7.37 7.38zm1.4 1.41l.01.01h-2.84l-7.37-7.38A30.95 30.95 0 0 1 6.84 60H0v-1.02a28.9 28.9 0 0 0 18.79-7.78L0 32.41v-4.84L18.78 8.79A28.9 28.9 0 0 0 0 1.02V0h6.84a30.95 30.95 0 0 1 13.35 7.38L27.57 0h2.84l7.39 7.38A30.95 30.95 0 0 1 51.16 0H60v27.58-.01V60h-8.84a30.95 30.95 0 0 1-13.37-7.4L30.4 60zM29 1.41l-7.4 7.38A30.95 30.95 0 0 1 29 22.16 30.95 30.95 0 0 1 36.38 8.8L29 1.4zM58 1A28.9 28.9 0 0 0 39.2 8.8L58 27.58V1.02zm-20.2 9.2A28.9 28.9 0 0 0 30.02 29h26.56L37.8 10.21zM30.02 31a28.9 28.9 0 0 0 7.77 18.79l18.79-18.79H30.02zm9.18 20.2A28.9 28.9 0 0 0 58 59V32.4L39.2 51.19zm-19-1.4a28.9 28.9 0 0 0 7.78-18.8H1.41l18.8 18.8zm7.78-20.8A28.9 28.9 0 0 0 20.2 10.2L1.41 29h26.57z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  &:before{
    content: ' ';
    display:block;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,.25);
    background: radial-gradient(circle, rgba(0,0,0,0.2637429971988795) 8%, rgba(0,0,0,0.6699054621848739) 91%);
  }
  .explainer{
    font-size:24px;
    line-height:1;
    margin:8vh 0 5vh 0;
    font-style:italic;
    opacity:0;
    transition: .75s opacity ease-out;
    transition-delay: 1s;
    position:relative;
    z-index:2;
  }
  .localHighScoreWrap{
    text-align: center;
    font-size:24px;
    font-weight:bold;
    position: relative;
    color:#5bad56;
  }

  .title{
    font-family: 'Abril Fatface', Helvetica, arial, sans;
    font-size:10vw;
    line-height:1;
    transition: 0.75s all ease-out;
    opacity:0;
    text-shadow: 5px 5px 20px rgba(0,0,0,.7);
    color:white;
    &.title1{
      transform:scale(1.7) translateX(-20vw);
      transition-delay: .25s;
    }
    &.title2{
      margin-top:-1vw;
      transform:scale(1.7) translateX(20vw);
      transition-delay: .5s;
    }
    &.title3{
      font-size:16vw;
      font-family: Pacifico, sans;
      margin-top:-4vw;
      color:#5bad56;
      transform:scale(1.7) translateY(-10vw);
      transition-delay: .75s;
    }
  }
  h1{
    span{

    }
  }
  &.introAnimate{
    .title{
      opacity:1;
      tansform:scale(1) translate(0);
      &.title1{
        transform:scale(1) translateX(-4vw);
      }
      &.title2{
        transform:scale(1) translateX(4vw);
      }
      &.title3{
        transform:scale(1) translateY(0);
      }
    }
    button{
      opacity:1;
    }
    .explainer{
      opacity:1;
    }
  }
}

.lowerButtons{
  max-width:90vw;
  margin:0 auto;
  padding:2vh 0;
  display:flex;
  justify-content: space-between;
}


