.app{
  width:100%;
  height:100%;
  overflow-x: hidden;
  min-height:100vh;
  padding:0;
  margin:0;
  background-color:#333;
}

.page{
  position:absolute;
  top:0;
  left:0;
  min-height:100vh;
  width:100%;
  opacity:0;
  //transform: scale(.7);
  filter: blur(15vw);
  transition: 0.5s all ease-out;
  pointer-events: none;
  max-height:100vh;
  overflow: hidden;
  &.activePage{
    opacity:1;
    filter:blur(0);
    //transform: scale(1);
    pointer-events: all;
    max-height:800vh;
  }
}
