@import "./btnGeneral.module";

.root{
  z-index:3;
  display:block;
  position:fixed;
  width:100%;
  height:100vh;
  top:0;
  left:0;
  padding: 20vh 0;
  background-color: rgba(0,0,0,.7);
  transition-delay:0.5s;
  text-align: center;
  overflow-y: hidden;
  opacity:0;
  transition: 0.3s opacity ease-out;
  pointer-events: none;
  &.active{
    pointer-events: all;
    opacity:1;
    transform:scale(1);
  }

}
.stage{
  display: inline-block;
  border-radius:1vw;
  border:0.5vw solid white;
  text-align:center;
  background-color: rgba(255,255,255,.7);
  color:black;
  padding:2vw 4vw;
  opacity:0;
  transform:scale(1.4);
  transition: 0.5s all ease-out;
  transition-delay: .25s;
  pointer-events: none;
  &.active{
    opacity:1;
    transform:scale(1);
    pointer-events: all;
  }
  .stageInner{
    display:flex;
    max-width:50vw;
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
    h2{
      width:100%;
      padding:1vh 0;
    }
    button{
      background-color: #5bad56;
      //color:white;
      //cursor:pointer;
    }
    p{
      font-size:18px;
      line-height:1.3;
    }
  }
}


@keyframes bounceOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
    opacity:1;
  }
  100% {
    transform: scale(0);
  }
}

.hsForm{
  display:none;
  input{
    font-size:2vw;
    padding:.5vw;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align:center;
  }
  &.hsFormActive{
    display:block;
    width:100%;
    font-size:2vw;
    line-height:1.2;
  }
}

.scoresLoading{
  display:none;
  width:100%;
  text-align:center;
  padding:2vw 0;
  &.scoresLoadingActive{
    display:block;
  }
}
.progress{
  color:#444;
}
