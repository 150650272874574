@import "./btnGeneral.module";

.mainContent {
  margin: 3vh 0 4vh 0;
  position: relative;
}
.root {
  width: 100%;
  min-height: 100vh;
  color: white;
  text-align: center;
  padding: 10vh 0;
  transition: 0.3s all ease-out;
  background-color: #333333;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 60 60'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23777777' fill-opacity='0.4' fill-rule='nonzero'%3E%3Cpath d='M29 58.58l7.38-7.39A30.95 30.95 0 0 1 29 37.84a30.95 30.95 0 0 1-7.38 13.36l7.37 7.38zm1.4 1.41l.01.01h-2.84l-7.37-7.38A30.95 30.95 0 0 1 6.84 60H0v-1.02a28.9 28.9 0 0 0 18.79-7.78L0 32.41v-4.84L18.78 8.79A28.9 28.9 0 0 0 0 1.02V0h6.84a30.95 30.95 0 0 1 13.35 7.38L27.57 0h2.84l7.39 7.38A30.95 30.95 0 0 1 51.16 0H60v27.58-.01V60h-8.84a30.95 30.95 0 0 1-13.37-7.4L30.4 60zM29 1.41l-7.4 7.38A30.95 30.95 0 0 1 29 22.16 30.95 30.95 0 0 1 36.38 8.8L29 1.4zM58 1A28.9 28.9 0 0 0 39.2 8.8L58 27.58V1.02zm-20.2 9.2A28.9 28.9 0 0 0 30.02 29h26.56L37.8 10.21zM30.02 31a28.9 28.9 0 0 0 7.77 18.79l18.79-18.79H30.02zm9.18 20.2A28.9 28.9 0 0 0 58 59V32.4L39.2 51.19zm-19-1.4a28.9 28.9 0 0 0 7.78-18.8H1.41l18.8 18.8zm7.78-20.8A28.9 28.9 0 0 0 20.2 10.2L1.41 29h26.57z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  &:before {
    content: " ";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 0.2637429971988795) 8%,
      rgba(0, 0, 0, 0.6699054621848739) 91%
    );
  }
  .explainer {
    font-size: 24px;
    line-height: 1.3;
    margin: 5vh 0;
    font-weight: normal;
    text-align: left;
    @media (max-width:768px){
      text-align: center;
    }
    &.explainerRight {
      text-align: right;
      @media (max-width:768px){
        text-align: center;
      }
    }
  }

  .title {
    font-family: "Abril Fatface", Helvetica, arial, sans-serif;
    font-size: 10vw;
    position: relative;
    line-height: 1;
    transition: 0.75s all ease-out;
    text-shadow: 5px 5px 20px rgba(0, 0, 0, 0.7);
    color: white;
  }
}

.figureRoot {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .htCard {
    width: 90px;
    height: 140px;
    border-radius: 4px;
    border: 3px solid white;
    margin: 0 20px;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: "Abril Fatface", Helvetica, arial, sans-serif;
    font-size: 50px;
    background-color: #424187;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 60 60'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.15' fill-rule='nonzero'%3E%3Cpath d='M29 58.58l7.38-7.39A30.95 30.95 0 0 1 29 37.84a30.95 30.95 0 0 1-7.38 13.36l7.37 7.38zm1.4 1.41l.01.01h-2.84l-7.37-7.38A30.95 30.95 0 0 1 6.84 60H0v-1.02a28.9 28.9 0 0 0 18.79-7.78L0 32.41v-4.84L18.78 8.79A28.9 28.9 0 0 0 0 1.02V0h6.84a30.95 30.95 0 0 1 13.35 7.38L27.57 0h2.84l7.39 7.38A30.95 30.95 0 0 1 51.16 0H60v27.58-.01V60h-8.84a30.95 30.95 0 0 1-13.37-7.4L30.4 60zM29 1.41l-7.4 7.38A30.95 30.95 0 0 1 29 22.16 30.95 30.95 0 0 1 36.38 8.8L29 1.4zM58 1A28.9 28.9 0 0 0 39.2 8.8L58 27.58V1.02zm-20.2 9.2A28.9 28.9 0 0 0 30.02 29h26.56L37.8 10.21zM30.02 31a28.9 28.9 0 0 0 7.77 18.79l18.79-18.79H30.02zm9.18 20.2A28.9 28.9 0 0 0 58 59V32.4L39.2 51.19zm-19-1.4a28.9 28.9 0 0 0 7.78-18.8H1.41l18.8 18.8zm7.78-20.8A28.9 28.9 0 0 0 20.2 10.2L1.41 29h26.57z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    position: relative;
    &.htCardOne {
      animation: cardSwap1 3s infinite;
      z-index: 2;
    }
    &.htCardTwo {
      animation: cardSwap2 3s infinite;
    }
    &.htCardThree {
      animation: cardSwap3 3s infinite;
    }
  }

  .clockAnim {
    font-size: 140px;
    animation: clockSwing 1s infinite;
  }
  .opsWrap {
    border-radius: 50%;
    background-color: orange;
    border: 4px solid #fff;
    padding: 12px 36px;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.6);
    animation: opsBounce 1s infinite ease-out;
    .opsTxt {
      font-size: 80px;
      color: #333;
    }
  }
  .parensRoot {
    font-size: 50px;
    line-height: 1;
    position: relative;
    padding:0 0 40px 0;
    .parensPairA,
    .parensPairB{
      display:inline-block;
    }
    .parensPairA {
      opacity: 0.3;
      animation: parensA 4s infinite;
    }
    .parensPairB {
      opacity: 0.3;
      animation: parensB 4s infinite;
    }
    .parensBtnA,
    .parensBtnB {
      position: absolute;
      top: 66px;
      left: 0px;
      height: 14px;
      width: 38%;
      border-bottom: 3px solid white;
      border-left: 3px solid white;
      border-right: 3px solid white;
      opacity: 0.3;
    }
    .parensBtnA {
      animation: parensAnimBtnA 4s infinite;
    }
    .parensBtnB {
      left: 185px;
      animation: parensAnimBtnB 4s infinite;
    }
  }
}

@media (max-width:768px){
  .rowRightTextCol{
    order:2;
  }
}

@keyframes parensAnimBtnA {
  0% {
    opacity: .3;
  }
  20% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: .3;
  }
  100% {
    opacity: .3;
  }
}
@keyframes parensAnimBtnB {
  0% {
    opacity: 1;
  }
  20% {
    opacity: .3;
  }
  50% {
    opacity: .3;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes parensA {
  0% {
    opacity: 0;
    transform: scale(2);
  }
  20% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  70% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
  }
}
@keyframes parensB {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  20% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(2);
  }
  70% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes opsBounce {
  0% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}

@keyframes cardSwap1 {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(130px);
  }
  80% {
    transform: translateX(130px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes cardSwap2 {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(30px);
  }
  25% {
    transform: translateX(-130px);
  }
  80% {
    transform: translateX(-130px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes cardSwap3 {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(10px);
  }
  25% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes clockSwing {
  0% {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(-20deg);
  }
}
