.cardFlipWrap{
  display:block;
  transform: rotateY(0);
  opacigty:1;
  //transition-duration: 300ms;
  transition: .3s all ease-out;
  &:nth-child(1){
    transition-delay: .5s;
  }
  &:nth-child(2){
    transition-delay: .7s;
  }
  &:nth-child(3){
    transition-delay: .9s;
  }
  &:nth-child(4){
    transition-delay: 1.1s;
  }
  &.flipComplete{
    transition: inherit;
    transition-delay:0;
  }
  &.flipped{
    opacity:0;
    transform: rotateY(90deg);
  }
}


.card{
  border: .5vw solid white;
  border-radius: 0.5vw;
  width:10vw;
  height:16vw;
  font-weight:800;
  font-size:8vw;
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  box-shadow: 0 0 1vw rgba(0,0,0,.5);
  transform: rotate(0deg);
  /* https://www.heropatterns.com/ */
  background-color: #424187;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 60 60'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.15' fill-rule='nonzero'%3E%3Cpath d='M29 58.58l7.38-7.39A30.95 30.95 0 0 1 29 37.84a30.95 30.95 0 0 1-7.38 13.36l7.37 7.38zm1.4 1.41l.01.01h-2.84l-7.37-7.38A30.95 30.95 0 0 1 6.84 60H0v-1.02a28.9 28.9 0 0 0 18.79-7.78L0 32.41v-4.84L18.78 8.79A28.9 28.9 0 0 0 0 1.02V0h6.84a30.95 30.95 0 0 1 13.35 7.38L27.57 0h2.84l7.39 7.38A30.95 30.95 0 0 1 51.16 0H60v27.58-.01V60h-8.84a30.95 30.95 0 0 1-13.37-7.4L30.4 60zM29 1.41l-7.4 7.38A30.95 30.95 0 0 1 29 22.16 30.95 30.95 0 0 1 36.38 8.8L29 1.4zM58 1A28.9 28.9 0 0 0 39.2 8.8L58 27.58V1.02zm-20.2 9.2A28.9 28.9 0 0 0 30.02 29h26.56L37.8 10.21zM30.02 31a28.9 28.9 0 0 0 7.77 18.79l18.79-18.79H30.02zm9.18 20.2A28.9 28.9 0 0 0 58 59V32.4L39.2 51.19zm-19-1.4a28.9 28.9 0 0 0 7.78-18.8H1.41l18.8 18.8zm7.78-20.8A28.9 28.9 0 0 0 20.2 10.2L1.41 29h26.57z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  .highlight{
    position:absolute;
    display:block;
    width:10vw;
    height:10vw;
    top:3vw;
    left:0;
    background-color: rgba(255,255,255,.9);
    background: radial-gradient(circle, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 70%);
    pointer-events: none;
    transform: scale(1);
    opacity:0;
    transition: 0.1s all ease-out;
    &.highlightActive{
      animation: cardHighlight 0.8s forwards;
    }
  }
  &.targetCard{
    margin:0 auto;
    background-color: #ab3636;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 60 60'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.15' fill-rule='nonzero'%3E%3Cpath d='M29 58.58l7.38-7.39A30.95 30.95 0 0 1 29 37.84a30.95 30.95 0 0 1-7.38 13.36l7.37 7.38zm1.4 1.41l.01.01h-2.84l-7.37-7.38A30.95 30.95 0 0 1 6.84 60H0v-1.02a28.9 28.9 0 0 0 18.79-7.78L0 32.41v-4.84L18.78 8.79A28.9 28.9 0 0 0 0 1.02V0h6.84a30.95 30.95 0 0 1 13.35 7.38L27.57 0h2.84l7.39 7.38A30.95 30.95 0 0 1 51.16 0H60v27.58-.01V60h-8.84a30.95 30.95 0 0 1-13.37-7.4L30.4 60zM29 1.41l-7.4 7.38A30.95 30.95 0 0 1 29 22.16 30.95 30.95 0 0 1 36.38 8.8L29 1.4zM58 1A28.9 28.9 0 0 0 39.2 8.8L58 27.58V1.02zm-20.2 9.2A28.9 28.9 0 0 0 30.02 29h26.56L37.8 10.21zM30.02 31a28.9 28.9 0 0 0 7.77 18.79l18.79-18.79H30.02zm9.18 20.2A28.9 28.9 0 0 0 58 59V32.4L39.2 51.19zm-19-1.4a28.9 28.9 0 0 0 7.78-18.8H1.41l18.8 18.8zm7.78-20.8A28.9 28.9 0 0 0 20.2 10.2L1.41 29h26.57z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

    opacity:0.5;
    &.targetCardEnabled{
      z-index:3;
      opacity:1;
      transition: .25s all ease-out;
      transform:scale(1);
      &:hover{
        transform:scale(1.25) rotate(-5deg);
      }
    }

  }
  &.dragging{
    cursor: grabbing;
    //transform: rotate(-10deg);
    box-shadow: 1vw 1vw 2vw rgba(0,0,0,.5);
    animation: swing 0.2s steps(10) infinite alternate;
  }
  div{
    width:100%;
    text-align:center;
    h2{
      font-size:8vw;
      color:white;
      /* NO SELECT */
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
}

@keyframes swing {
  from { transform: rotate(-5deg) }
  to   { transform: rotate(-10deg) }
}

@keyframes cardHighlight {
  0%{
    opacity:1;
    transform:scale(3);
  }
  100%{
    opacity:0;
    transform:scale(.5);
  }
}
