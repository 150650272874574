@import url('https://fonts.googleapis.com/css?family=Abril+Fatface|Pacifico|Montserrat&display=swap');
$font-family-default: Montserrat, Helvetica, arial, sans;
$font-family-headline: 'Abril Fatface', Helvetica, arial, sans;

html, body{
  margin:0;
  padding:0;
  font-size: 14px;
  background-color: #333;
  width:100%;
  overflow-x:hidden;
}
body{
}
* {
  font-family: $font-family-default;
  &:focus {
    outline: none;
  }
}
a{
  text-decoration: none;
}
.title,
h1{
  font-family: $font-family-headline;
  line-height: 1.2;
  font-size: 7rem;
  margin: 0 0 2rem 0;
}
h2{
  font-family: $font-family-headline;
  line-height: 1;
  font-size: 3rem;
  margin: 0;
}


//button{
//  -moz-appearance: none;
//  -webkit-appearance: none;
//  font-size:40px;
//  border-radius:10px;
//  cursor:pointer;
//  border: 6px solid black;
//  box-shadow: 3px 3px 20px rgba(0,0,0,.3);
//  padding:1vw;
//  background-color: red;
//  color:white;
//  transform:scale(1);
//  transition:0.25s transform ease-in-out;
//  &:hover{
//    transform:scale(1.3);
//  }
//}



@keyframes bounceOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
    opacity:1;
  }
  100% {
    transform: scale(0);
  }
}
