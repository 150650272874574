@import "./btnGeneral.module";

.root{
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: relative;
  background: rgb(56,107,38);
  background: radial-gradient(circle, rgba(56,107,38,1) 0%, rgba(30,66,17,1) 100%);
}
.gameBoard {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: relative;
  min-height: 100vh;
  padding: 3vh 0;
}
.sortabnleContainer {
  width: 90vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  perspective: 1000px; // for card flip
}

.targetCardWrap,
.cardWrap{
  display:block;
  transform: rotateY(0);
  transition: .3s transform ease-out;
  &.flipped{
    transform: rotateY(90deg);
  }
}

//.gameBoardIsSorting{
//    background-color: aqua;
//}

.lowerTray {
  padding: 10vh 5vw 6vh 0;
  text-align: right;
}

.targetCardWrap {
  //display: flex;
  //align-items: center;
  transition-delay: 1.5s;
}

.equalsSign {
  font-size: 10vh;
  color: rgba(255, 255, 255, 0.5);
  margin-right: 1vw;
}

.parensButtons {
  position: relative;
  height: 8vh;
  div {
    cursor: pointer;
    border-bottom: 0.5vh solid white;
    border-left: 0.5vh solid white;
    border-right: 0.5vh solid white;
    position: absolute;
    opacity: 0.3;
    &.parensBtnAvtive {
      opacity: 1;
    }
    &.parensBtn2a {
      width: 22vw;
      height: 2vh;
      top: 1vh;
      left: 12vw;
    }
    &.parensBtn2b {
      width: 22vw;
      height: 2vh;
      top: 1vh;
      left: 39vw;
    }
    &.parensBtn2c {
      width: 22vw;
      height: 2vh;
      top: 1vh;
      left: 65vw;
    }

    &.parensBtn3a {
      width: 49vw;
      height: 2vh;
      top: 5vh;
      left: 12vw;
    }
    &.parensBtn3b {
      width: 49vw;
      height: 2vh;
      top: 9vh;
      left: 39vw;
    }
  }
}
.parensDisplay {
  position: absolute;
  width: 100%;
  height: 16vw;
  div {
    position: absolute;
    font-size: 11vw;
    color: rgba(255, 255, 255, 0.7);
    top: 0;
    opacity: 0;
    transform: scale(1.5);
    transition: 0.3s all ease-out;
    /* NO SELECT */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.parensActive {
      opacity: 1;
      transform: scale(1);
    }
    &.parens2a1 {
      left: 1vw;
    }
    &.parens2a2 {
      left: 16.5vw;
    }
    &.parens2b1 {
      left: 27.5vw;
    }
    &.parens2b2 {
      left: 43vw;
    }
    &.parens2c1 {
      left: 54vw;
    }
    &.parens2c2 {
      left: 69vw;
    }
    &.parens2d1 {
      left: 80.5vw;
    }
    &.parens2d2 {
      left: 95.5vw;
    }
  }
}

.upperTray{
  text-align:center;
}





.btnGeneralWrap {
  z-index:1;
  transform:scale(1);
  transition:.25s all ease-in-out;
  display: inline-block;
  .btnGeneral{
    border-radius:1vw;
    font-size:2vw;
    background-color:white;
    border:0.5vw solid #222;
    box-shadow: 2px 2px 20px rgba(0,0,0,.7);
    transition:.25s all ease-in-out;
    .btnIcon{
      font-size:3vw;
      margin-right:1vw;
    }
  }
  &:hover{
    transform:scale(1.3);
    .btnGeneral {
      background-color: #444;
      color:white;
    }
  }
}
